<template>
  <div class="flex gap-4 items-center" @click="changeSelection()">
    <div
      class="checkbox flex cursor-pointer align-middle justify-center min-w-5 h-5 border border-grayscale-16"
      :class="isSelected ? 'bg-grayscale-96' : 'bg-grayscale-7'"
    >
      <i
        v-if="isSelected"
        class="icon-checkmark-2 text-16 leading-5 text-grayscale-7"
      />
    </div>
    <p>{{ value }}</p>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";

const props = defineProps({
  filter: {
    type: String,
    default: "",
  },
  value: {
    type: String,
    default: "",
  },
  selected: {
    type: Boolean,
    default: false,
  },
  additionalClasses: {
    type: String,
    default: "",
  },
  id: {
    type: String,
    default: "",
  },
});

watch(
  () => props.selected,
  () => {
    isSelected.value = props.selected;
  },
);

const emit = defineEmits(["selectionChanged:selected"]);

const isSelected = ref(props.selected);

const changeSelection = () => {
  isSelected.value = !isSelected.value;
  emit("selectionChanged:selected", isSelected);
};
</script>

<style scoped>
.checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border-radius: 3px;
  outline: none;
  transition-duration: 0.3s;
  cursor: pointer;
}
</style>
